::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: transparent;
}

.MuiFilledInput-underline::before {
    border-bottom: 1px solid #ffffff !important;
}

#talent-builder {
    background-color: #1f1f1f;
    height: 100%;
    width: 100%;

    .Overall {
        left: 13px !important;
    }

    .talent-type-wrapper-Marksman {
        .talent-section-image {
            left: 20% !important;
        }

        .Marksman {
            left: 13px !important;
        }
    }

    .talent-type-wrapper-Precision {
        .talent-section-image {
            left: 15% !important;
        }

        .Precision {
            left: 12px !important;
        }
    }

    .talent-type-wrapper-Engineering {
        .Engineering {
            left: 14px !important;
        }
    }

    .Support {
        left: 13px !important;
    }

    .talents {
        .unlocked {
            opacity: 0.8 !important;
        }
        
        .unavailable {
            opacity: 0.5 !important;
        }
    }

    #talent-tree {
        cursor: grab;  
        zoom: 40%;
        margin-top: 100px;
        margin-left: 300px;
        min-height: 100vh;
        position: relative;
    
        .talent-section-header {
            text-align: center;
            color: #ffffff;
            font-family: 'Lilith';
            font-weight: bold;
            font-size: 0.6rem;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
    
            .talent-section-image {
                left: 13rem;
                width: 60px;
                height: 60px;
                position: relative;
    
                .talent-type {
                    position: absolute;
                    top: 0.8rem;
                    left: 1rem;
                    z-index: 1;
                }
    
                .talent-border {
                    position: absolute;
                    left: 0;
                    opacity: 0.3;
                    z-index: 0;
                }
            }
        }
    
        .talent-section-selected {
            h1 {
                color: #ffe0a9;
            }
        }
    
        .talent-section-complete {
            .talent-border {
                opacity: 1 !important;
            }
    
            h1 {
                color: #ffe0a9;
            }
        }
    
        #core {
            top: 20%;
            left: 37%;
            height: 12rem;
            width: 30rem;
            position: relative;
    
            .talents {
                position: relative;
            }
    
            .talent {
                position: absolute;
                top: 0;
                left: 33%;
                
                .talent_image {
                    width: 6rem;
                    top: 2rem;
                    left: 1.9rem;
                }
            }
        }
    
        #left {
            position: relative;
            left: 37%;
            height: 20rem;
            width: 0;
            margin-top: -20rem;
            margin-left: -85rem;
            padding-right: 90rem;
    
            .talent-section-header {
                position: absolute;
                top: 12rem;
                right: 0;
    
                .talent-section-image {
                    left: 0;
                }
            }
    
            .talent {
                position: absolute;
                height: 6rem;
                width: 5rem;
    
                &:nth-child(1) {
                    top: 9rem;
                    right: 7rem;
                }
    
                &:nth-child(2) {
                    top: 5rem;
                    right: 15rem;
                }
    
                &:nth-child(3) {
                    top: -4rem;
                    right: 12rem;
                }
    
                &:nth-child(4) {
                    top: 14rem;
                    right: 18rem;
                }
    
                &:nth-child(5) {
                    top: 1rem;
                    right: 25rem;
                }
    
                &:nth-child(6) {
                    top: -8rem;
                    right: 22rem;
                }
    
                &:nth-child(7) {
                    top: 10rem;
                    right: 28rem;
                }
    
                &:nth-child(8) {
                    top: -10rem;
                    right: 32rem;
                }
    
                &:nth-child(9) {
                    top: 1rem;
                    right: 35rem;
                }
    
                &:nth-child(10) {
                    top: -8rem;
                    right: 46rem;
                }
    
                &:nth-child(11) {
                    top: -18rem;
                    right: 42rem;
                }
    
                &:nth-child(12) {
                    top: 2rem;
                    right: 50rem;
                }
    
                &:nth-child(13) {
                    top: -12rem;
                    right: 56rem;
                }
    
                &:nth-child(14) {
                    top: -23rem;
                    right: 52rem;
                }
    
                &:nth-child(15) {
                    top: -1rem;
                    right: 60rem;
                }
    
                &:nth-child(16) {
                    top: -16rem;
                    right: 66rem;
                }
    
                &:nth-child(17) {
                    top: -27rem;
                    right: 62rem;
                }
    
                &:nth-child(18) {
                    top: -4rem;
                    right: 70rem;
                }
    
                &:nth-child(19) {
                    top: -30rem;
                    right: 72rem;
                }
    
                &:nth-child(20) {
                    top: -16rem;
                    right: 76rem;
                }
            }
        }
    
        #right {
            position: relative;
            left: 37%;
            height: 20rem;
            width: 200px;
            margin-top: -20rem;
            margin-left: 25.5rem;
            padding-right: 90rem;
            display: block;
    
            .talent-section-header {
                position: absolute;
                top: 12rem;
                left: 0;
    
                .talent-section-image {
                    left: 0;
                }
            }
    
            .talent {
                position: absolute;
                height: 6rem;
                width: 5rem;
    
                &:nth-child(1) {
                    top: 9rem;
                    left: 7rem;
                }
    
                &:nth-child(2) {
                    top: 5rem;
                    left: 15rem;
                }
    
                &:nth-child(3) {
                    top: -4rem;
                    left: 12rem;
                }
    
                &:nth-child(4) {
                    top: 14rem;
                    left: 18rem;
                }
    
                &:nth-child(5) {
                    top: 1rem;
                    left: 25rem;
                }
    
                &:nth-child(6) {
                    top: -8rem;
                    left: 22rem;
                }
    
                &:nth-child(7) {
                    top: 10rem;
                    left: 28rem;
                }
    
                &:nth-child(8) {
                    top: -10rem;
                    left: 32rem;
                }
    
                &:nth-child(9) {
                    top: 1rem;
                    left: 35rem;
                }
    
                &:nth-child(10) {
                    top: -8rem;
                    left: 46rem;
                }
    
                &:nth-child(11) {
                    top: -18rem;
                    left: 42rem;
                }
    
                &:nth-child(12) {
                    top: 2rem;
                    left: 50rem;
                }
    
                &:nth-child(13) {
                    top: -12rem;
                    left: 56rem;
                }
    
                &:nth-child(14) {
                    top: -23rem;
                    left: 52rem;
                }
    
                &:nth-child(15) {
                    top: -1rem;
                    left: 60rem;
                }
    
                &:nth-child(16) {
                    top: -16rem;
                    left: 66rem;
                }
    
                &:nth-child(17) {
                    top: -27rem;
                    left: 62rem;
                }
    
                &:nth-child(18) {
                    top: -4rem;
                    left: 70rem;
                }
    
                &:nth-child(19) {
                    top: -30rem;
                    left: 72rem;
                }
    
                &:nth-child(20) {
                    top: -16rem;
                    left: 76rem;
                }
            }
        }
    
        .elite {
            width: 8rem !important;
        }
    
        .core {
            width: 10rem !important;
        }
        
        .elite .talent_image {
            width: 5rem;
            top: 1.5rem;
            left: 1.5rem;
        }
    
        .core .talent_image {
            width: 6rem;
            top: 1.9rem;
            left: 1.9rem;
        }
    
        #top {
            top: 20%;
            left: 37%;
            position: relative;
            height: 90rem;
            margin-bottom: 6rem;
            width: 30rem;
    
            .talent-section-header {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
    
            .talent {
                position: absolute;
                height: 6rem;
                width: 5rem;
    
                &:nth-child(1) {
                    bottom: 8rem;
                    right: 12.5rem;
                }
    
                &:nth-child(2) {
                    bottom: 15rem;
                    left: 4.5rem;
                }
    
                &:nth-child(3) {
                    bottom: 17rem;
                    right: 12.5rem;
                }
    
                &:nth-child(4) {
                    bottom: 15rem;
                    right: 4.5rem;
                }
    
                &:nth-child(5) {
                    bottom: 25rem;
                    left: 4.5rem;
                }
    
                &:nth-child(6) {
                    bottom: 27rem;
                    right: 12.5rem;
                }
    
                &:nth-child(7) {
                    bottom: 25rem;
                    right: 4.5rem;
                }
    
                &:nth-child(8) {
                    bottom: 38rem;
                    left: 6rem;
                }
    
                &:nth-child(9) {
                    bottom: 38rem;
                    right: 5.5rem;
                }
    
                &:nth-child(10) {
                    bottom: 48rem;
                    left: 4.5rem;
                }
    
                &:nth-child(11) {
                    bottom: 50rem;
                    right: 12.5rem;
                }
    
                &:nth-child(12) {
                    bottom: 48rem;
                    right: 4.5rem;
                }
    
                &:nth-child(13) {
                    bottom: 58rem;
                    left: 3.5rem;
                }
    
                &:nth-child(14) {
                    bottom: 60rem;
                    right: 12.5rem;
                }
    
                &:nth-child(15) {
                    bottom: 58rem;
                    right: 3.5rem;
                }
    
                &:nth-child(16) {
                    bottom: 68rem;
                    left: 2.5rem;
                }
    
                &:nth-child(17) {
                    bottom: 70rem;
                    right: 12.5rem;
                }
    
                &:nth-child(18) {
                    bottom: 68rem;
                    right: 2.5rem;
                }
    
                &:nth-child(19) {
                    bottom: 83rem;
                    left: 4rem;
                }
    
                &:nth-child(20) {
                    bottom: 83rem;
                    right: 4rem;
                }
            }
        }
    
        #foundation {
            top: 20%;
            left: 37%;
            position: relative;
            height: 25rem;
            width: 30rem;
    
            .talents {
                position: relative;
                height: 25rem;
                width: 30rem;
            } 
    
            .talent {
                position: absolute;
    
                &:nth-child(1) {
                    bottom: 0;
                    left: 12.5rem;
                }
    
                &:nth-child(2) {
                    top: 10rem;
                    left: 6.5rem;
                }
    
                &:nth-child(3) {
                    top: 10rem;
                    right: 6.5rem;
                }
    
                &:nth-child(4) {
                    top: 0;
                    left: 4.5rem;
                }
    
                &:nth-child(5) {
                    top: 4rem;
                    right: 12.5rem;
                }
    
                &:nth-child(6) {
                    top: 0;
                    right: 4.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #talent-builder {
        #talent-tree {
            margin-top: 20rem;
            margin-left: 85rem;
        }
    }
}