.talent {
    text-align: center;
    font-family: 'Lilith';
    font-weight: bold;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.2s ease;

    .talent-active {
        position: absolute;
        height: 8rem;
        top: -0.8rem;
        left: -1rem;
        z-index: -1;
        width: 7rem;
    }

    img {
        cursor: pointer;
    }

    p {
        color: #ffffff;
    }

    .normal {
        width: 5rem;
    }

    .elite {
        width: 8rem;
    }

    .core {
        width: 10rem;
    }
    
    .talent_image {
        position: absolute;
        top: 18px;
        width: 60px;
        left: 10px;
    }

    &:active {
        transform: scale(0.9);
    }
}

.core .talent-active {
    height: 13rem;
    left: 0rem;
    top: -1.4rem;
    width: 10rem;
}

.elite .talent-active {
    height: 10rem;
    left: -0.5rem;
    top: -0.8rem;
    width: 9rem;
}