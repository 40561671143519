* {
    color: #ffffff;
    font-family: 'Lilith';
}

.chart-container {
    height: 600px;
}

#alliance {
    background-color: #1f1f1f;
    height: 100%;
    width: 100%;
    position: relative;
}

#login {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    button {
        margin-top: 1rem !important;
    }
}