@font-face {
    font-family: 'Lilith';
    font-style: normal;
    font-weight: normal;
    src: local('Lilith Normal'), local('Lilith-Normal'), url(./Lilith/font.ttf);
}

@font-face {
    font-family: 'Lilith';
    font-style: bold;
    font-weight: bold;
    src: local('Lilith Bold'), local('Lilith-Bold'), url(./Lilith/font_bold_vi.ttf);
}

@font-face {
    font-family: 'Lilith';
    font-style: bold;
    font-weight: 600;
    src: local('Lilith Bold Styled'), local('Lilith-Bold-Styled'), url(./Lilith/font_bold.ttf);
}