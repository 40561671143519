#handler {
    color: #ffffff;

    .jsoneditor {
        height: 100vh !important;
        width: 50% !important;
        float: left;
        border-color: #000000;
    
        .jsoneditor-menu {
            background-color: #000000;
        }
    
        .jsoneditor-poweredBy {
            display: none;
        }
    }
}